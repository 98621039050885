<template>
   <section class="cont">
     <el-row class="crumbs-box">
       <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>数据中台</el-breadcrumb-item>
         <el-breadcrumb-item>会员画像</el-breadcrumb-item>
         <el-breadcrumb-item>消费画像</el-breadcrumb-item>
       </el-breadcrumb>
     </el-row>
      <el-row class="bottom">
         <el-col :span="8" class="bottom-bg">
            <el-row class="bottom-left">
              <el-row class="title">商城销售统计</el-row>
              <el-row style="padding: 0 10px 10px 10px">
                <el-row id="bottom-left-pie" style=" height: 220px;"></el-row>
              </el-row>
            </el-row>
         </el-col>
         <el-col :span="8" class="bottom-bg">
            <el-row class="bottom-center">
              <el-row class="title">转化率统计</el-row>
              <el-row style="padding: 0 10px 10px 10px">
                <el-row id="bottom-center-bar" style="height: 220px;"></el-row>
              </el-row>
            </el-row>
         </el-col>
         <el-col :span="8" class="bottom-bg">
            <el-row class="bottom-right">
              <el-row class="title">订单来源分析</el-row>
              <el-row style="padding: 0 10px 10px 10px">
                <el-row id="bottom-right-pie" style=" height: 220px;"></el-row>
              </el-row>
            </el-row>
         </el-col>
      </el-row>
      <el-row class="user-num">
         <el-col :span="12" class="user-bg" >
            <el-row class="user-item">
               <el-row class="user-head">
                 <el-row class="title">消费画像</el-row>
                  <el-row class="user-flex">
                     <p class="m-right-10">过去7日，按日，所有用户</p>
                     <el-date-picker
                             v-model="statisticsDay1"
                             align="right"
                             type="date"
                             placeholder="选择日期"
                             value-format="yyyy-MM-dd"
                             :picker-options="dayPickerOptions">
                     </el-date-picker>
                  </el-row>
               </el-row>
               <el-row id="consumption-portrait" style="width: 100%; height: 360px;margin-top: 1rem;"></el-row>
            </el-row>
         </el-col>
         <el-col :span="12" class="user-bg" style="padding-left: 10px">
            <el-row class="user-item">
               <el-row class="user-head">
                 <el-row class="title">优惠券使用数量分布</el-row>
                  <el-row class="user-flex">
                     <p class="m-right-10">过去7日，按日，所有用户</p>
                     <el-date-picker
                             v-model="statisticsDay2"
                             align="right"
                             type="date"
                             placeholder="选择日期"
                             value-format="yyyy-MM-dd"
                             :picker-options="dayPickerOptions">
                     </el-date-picker>
                  </el-row>
               </el-row>
               <el-row id="coupon" style="width: 100%; height: 360px; margin-top: 1rem;"></el-row>
            </el-row>
         </el-col>
      </el-row>
      <el-row style="background-color: #ffffff; margin-top: 20px">
         <el-row class="">
           <el-row class="title">购物爱好</el-row>
            <el-row class="chart" id="shop-like-bar" style="width: 90%; height: 360px; margin-top: 1rem;"></el-row>
         </el-row>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   export default {
      data() {
         return{
            useData: [],
            surveyData: {},  // 概况数据
            score: '',
            index: 1,
            hotelId: '',
            pieOptions1: {
               legend: {
                  orient: 'vertical',
                  left: 10,
                  top: 40,
                  data: []
               },
               color: [ '#ec5236', '#2ee4bb', '#0474fe', '#ffa333' ],
               tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
               },
               series: [
                  {
                     name: '已售商品',
                     type: 'pie',
                     radius: ['50%', '70%'],
                     avoidLabelOverlap: false,
                     label: {
                        show: false,
                        position: 'center'
                     },
                     emphasis: {
                        label: {
                           show: true,
                           fontSize: '14',
                           fontWeight: 'bold'
                        }
                     },
                     labelLine: {
                        show: false
                     },
                     data: []
                  }
               ]
            },
            barOptions: {
               legend: {
                  data: []
               },
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'shadow'
                  }
               },
               color: ['#0474fe'],
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: {},
               yAxis: {
                  type: 'category',
                  data: []
               },
               series: [
                  {
                     name: '直接访问',
                     type: 'bar',
                     barWidth: '60%',
                     data: [],
                     itemStyle: {
                        normal: {
                           label: {
                              show: true,		  //开启显示
                              position: 'right',	//在上方显示
                              textStyle: {	    //数值样式
                                 color: 'black',
                                 fontSize: 14
                              }
                           }
                        }
                     }
                  }
               ]
            },
            pieOptions2: {
               tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
               },
               legend: {
                  orient: 'vertical',
                  left: 10,
                  top: 40,
                  data: ['小程序','钉钉','前台','自助机', 'APP','PC端']
               },
               color: [ '#ec5236', '#2ee4bb', '#0474fe', '#ffa333' ],
               series: [
                  {
                     name: '订单来源',
                     type: 'pie',
                     radius: '55%',
                     center: ['50%', '60%'],
                     data: [
                        {value: 335, name: '小程序'},
                        {value: 135, name: '自助机'},
                     ],
                     emphasis: {
                        itemStyle: {
                           shadowBlur: 10,
                           shadowOffsetX: 0,
                           shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                     }
                  }
               ]
            },
            statisticsDay1: '',
            statisticsDay2: '',
            dayPickerOptions: {
               disabledDate(time) {
                  return time.getTime() > Date.now();
               },
               shortcuts: [{
                  text: '今天',
                  onClick(picker) {
                     picker.$emit('pick', new Date());
                  }
               }, {
                  text: '昨天',
                  onClick(picker) {
                     const date = new Date();
                     date.setTime(date.getTime() - 3600 * 1000 * 24);
                     picker.$emit('pick', date);
                  }
               }, {
                  text: '一周前',
                  onClick(picker) {
                     const date = new Date();
                     date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                     picker.$emit('pick', date);
                  }
               }]
            },
            couponOptions: {
               color: ['#80FFA5', '#00DDFF', '#0474fe', '#ff0087', '#FFBF00'],
               tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                     type: 'cross',
                     label: {
                        backgroundColor: '#6a7985'
                     }
                  }
               },
               legend: {
                  data: ['5%折扣券', '早餐券', '200满减券']
               },
               toolbox: {
                  feature: {
                     saveAsImage: {}
                  }
               },
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: [
                  {
                     type: 'category',
                     boundaryGap: false,
                     data: ['2021.1.25', '2021.1.26', '2021.1.27', '2021.1.28', '2021.1.29', '2021.1.30', '2021.1.31']
                  }
               ],
               yAxis: [
                  {
                     type: 'value'
                  }
               ],
               series: [
                  {
                     name: '5%折扣券',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(128, 255, 165)'
                        }, {
                           offset: 1,
                           color: 'rgba(1, 191, 236)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [140, 232, 101]
                  },
                  {
                     name: '早餐券',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(0, 221, 255)'
                        }, {
                           offset: 1,
                           color: 'rgba(77, 119, 255)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [120, 282, 111, 234, 220, 340, 310]
                  },
                  {
                     name: '200满减券',
                     type: 'line',
                     stack: '总量',
                     smooth: true,
                     lineStyle: {
                        width: 0
                     },
                     showSymbol: false,
                     areaStyle: {
                        opacity: 0.8,
                        color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                           offset: 0,
                           color: 'rgba(55, 162, 255)'
                        }, {
                           offset: 1,
                           color: 'rgba(116, 21, 219)'
                        }])
                     },
                     emphasis: {
                        focus: 'series'
                     },
                     data: [320, 132, 201, 334, 190, 130, 220]
                  }
               ]
            },
            lineOptions: {
               xAxis: {
                  type: 'category',
                  data: ['2021/12/22', '2021/12/24', '2021/12/26', '2021/1/28']
               },
               yAxis: {
                  type: 'value'
               },
               series: [{
                  data: [2312, 4100, 4112, 6431,],
                  type: 'line',
                  smooth: true,
                  itemStyle: {
                     normal: {
                        color: '#1e80ff', //改变折线点的颜色
                        lineStyle: {
                           color: '#1e80ff' //改变折线颜色
                        }
                     }
                  },
               }]
            },
            /* 购物爱好 */
            barOptions1: {
               color: ['#0474fe'],
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               xAxis: {},
               yAxis: {
                  type: 'category'
               },
               dataset: {
                  source: [
                     ['score', 'amount', 'product'],
                     [89.7, 10, '其他类'],
                     [89.3, 40, '饮品'],
                     [50.1, 100, '门票'],
                     [57.1, 80, '小吃'],
                     [74.4, 60, '特产'],
                  ]
               },
               series: [
                  {
                     name: '直接访问',
                     type: 'bar',
                     barWidth: '60%',
                     encode: {
                        // 将 "amount" 列映射到 X 轴。
                        x: 'amount',
                        // 将 "product" 列映射到 Y 轴。
                        y: 'product'
                     },
                     itemStyle: {
                        normal: {
                           label: {
                              show: true,		    //开启显示
                              position: 'right',
                              textStyle: {	    //数值样式
                                 color: 'black',
                                 fontSize: 14
                              }
                           }
                        }
                     }
                  }
               ]
            },
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.hotelId = this.hotelInfo.id
         let coupon = this.$echarts.init(document.getElementById('coupon'));
         coupon.setOption(this.couponOptions);
         let portrait = this.$echarts.init(document.getElementById('consumption-portrait'));
         portrait.setOption(this.lineOptions)
         let shop = this.$echarts.init(document.getElementById('shop-like-bar'))
         shop.setOption(this.barOptions1)
         let leftpie = this.$echarts.init(document.getElementById('bottom-left-pie'))
         let rightpie = this.$echarts.init(document.getElementById('bottom-right-pie'))
         let centerbar = this.$echarts.init(document.getElementById('bottom-center-bar'))
         this.getSaleData()
         this.getOrderSource()
         this.getConversion()
         window.onresize=function(){
            coupon.resize()
            portrait.resize()
            shop.resize()
            leftpie.resize()
            rightpie.resize()
            centerbar.resize()
         }


      },
      methods: {
         // 获取商城销售统计
         getSaleData(){
            const url = urlObj.saleInfo
            const param = { hotelId: this.hotelId }
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  let saleData = res.records
                  saleData = saleData.map(item => {
                     return { value: item.saleCount, name: item.categoryName }
                  })
                  this.pieOptions1.legend.data = saleData.map(item => item.name)
                  this.pieOptions1.series[0].data = saleData
                        this.$echarts.init(document.getElementById('bottom-left-pie')).setOption(this.pieOptions1);

               }
            })
         },
         // 获取订单来源统计
         getOrderSource(){
            const url = urlObj.orderSource
            const param = { orderType: 'HOTEL', hotelId: this.hotelId}
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  let orderData = res.records
                  orderData = orderData.map(item => {
                     let name
                     if (item.orderSource === 'SSM') name = '自助机'
                     if (item.orderSource === 'WECHAT_APPLET') name = '微信小程序'
                     if (item.orderSource === 'DINGTALK') name = '钉钉'
                     if (item.orderSource === 'FRONT_DESK') name = '前台'
                     if (item.orderSource === 'APP') name = 'APP'
                    if (item.orderSource === 'WEB') name = 'WEB'
                    if (item.orderSource === 'DOUYIN_APPLET') name = '抖音小程序'
                    if (item.orderSource === 'DOUYIN_PLATFORM') name = '抖音平台'

                     return { name, value: item.orderCount }
                  })
                  this.pieOptions2.series[0].data = orderData
                  // 订单来源分析
                       this.$echarts.init(document.getElementById('bottom-right-pie')).setOption(this.pieOptions2);
               }
            })
         },
         // 获取转化率统计
         getConversion(){
            const url = urlObj.conversion
            const param = {hotelId: this.hotelId}
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  let convData = res.records
                  this.barOptions.yAxis.data = []
                  this.barOptions.series[0].data = []
                  for (let key in convData){
                     let name
                     if (key === 'ORDER') name = '订单用户'
                     if (key === 'MALL') name = '购物用户'
                     if (key === 'CONTROL') name = '客控用户'
                     if (key === 'USER') name = '用户总数'
                     if (key === 'APPLET') name = '小程序用户'
                     this.barOptions.yAxis.data.push(name)
                     this.barOptions.series[0].data.push(convData[key])
                  }
                  // 转化率统计
                  this.$echarts.init(document.getElementById('bottom-center-bar')).setOption(this.barOptions);
               }
            })
         },
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id  && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.getSaleData()
                  this.getOrderSource()
                  this.getConversion()
               }
            },
            deep: true
         }
      },
   }
</script>

<style scoped lang="scss">
   .cont{
      min-width: auto;
   }
   .title{
      height:50px;
      line-height: 50px;
      background: #f7f7f7;
      font-size: 18px;
      padding:0 0 0 30px;
   }
   .cont .top{
      height: 500px;
   }
   .cont .top .top-left{
      padding: 0 5px 0 0;
   }
   .cont .top .top-left .top-left-bg{
      background-color: #FFFFFF;
      height: 494px;
      padding: 20px 0 0 20px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
   }
   .cont .top .top-left .top-left-list{
      line-height: 46px;
   }
   .cont .top .top-left .top-left-list .el-row{
      width: 80%;
      padding: 20px;
      margin: 30px auto 0;
      border-radius: 8px;
      border: solid 1px #409eff;
      font-size: 14px;
   }
   .cont .top .top-left .top-left-list .el-row p{
      line-height: 36px;
      border-bottom: 1px solid #409eff;
   }
   .cont .top .top-left .top-left-list .el-row p b{
      color: #409eff;
      font-size: 15px;
   }

   .cont .top .top-right{
      height: 500px;
   }
   .cont .top .top-right .top-right-bg{
      height: 50%;
      padding-bottom: 5px;
   }
   .cont .top .top-right-top,
   .cont .top .top-right-bottom {
      background-color: #FFFFFF;
      height: 100%;
      padding: 16px;
      border-radius: 6px;
   }
   .cont .top .top-right-top{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
   }
   .cont .top .top-right-top h3,
   .cont .top .top-right-bottom h3{
      color: #409eff;
   }
   .cont .top .top-right-top h3{
      margin-bottom: 10px;
   }
   .cont .top .top-right-top p{
      line-height: 36px;
      font-size: 14px;
   }
   .cont .top .top-right-bottom p{
      font-size: 14px;
      margin: 8px 0;
   }
   .cont .top .top-right-bottom p:nth-child(1){
      margin-top: 30px;
   }
   .cont .top .top-right-bottom p:nth-child(2){
      font-size: 30px;
      font-weight: bold;
      color: #fe4703;
   }
   .cont .top .top-right-bottom p:nth-child(3){
      font-weight: bold;
   }
   .cont .top .top-right-bottom .text button{
      background-color: transparent;
      border: solid 1px #409eff;
      border-radius: 12px;
      padding: 3px 8px;
      color: #409eff;
   }
   .cont .top .top-right-bottom .img{
      text-align: center;
   }
   .cont .top .top-right-bottom .img img{
      width: 190px;
      display: block;
      margin: 0 auto;
   }
   .cont .top .top-right-bottom .img span{
      font-weight: bold;
   }
   .cont .bottom .bottom-bg:nth-child(1){
     padding: 0 10px 0 0;
   }
   .cont .bottom .bottom-bg:nth-child(2){
      padding: 0 10px 0;
   }
   .cont .bottom .bottom-bg:nth-child(3){
     padding: 0 0 0 10px;
   }
   .cont .bottom .bottom-bg > .el-row{
      background-color: #FFFFFF;
      border-radius: 6px;
   }
   .cont .bottom .el-row h3{
      color: #409eff;
   }

   .user-num{
      margin-top: 20px;
      .user-item{
         background-color: #ffffff;
         .user-head p{
            line-height: 28px;
         }
         .user-head .user-title{
            font-size: 15px;
            border-bottom: solid 1px #EBEEF5;
         }
         .user-flex {
            display: flex; margin: 10px 0 0 20px;
         }
         .user-flex > p{
            font-size: 13px;
         }
         .user-table{
            margin-top: 10px;
         }
      }
   }

</style>
