import { render, staticRenderFns } from "./consumePro.vue?vue&type=template&id=2e39633c&scoped=true&"
import script from "./consumePro.vue?vue&type=script&lang=js&"
export * from "./consumePro.vue?vue&type=script&lang=js&"
import style0 from "./consumePro.vue?vue&type=style&index=0&id=2e39633c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e39633c",
  null
  
)

export default component.exports